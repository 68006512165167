import Header from "./components/Header";
import VideoList from "./components/VideoList";
import "./scss/main.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <VideoList />
    </div>
  );
}

export default App;
