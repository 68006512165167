import React, { useEffect, useState } from "react";
import Video from "./Video";

export default function VideoList() {
  const [allVideos, setAllVideos] = useState([]);
  const [liveStream, setLiveStream] = useState([]);

  async function fetchVideos() {
    const response = await fetch(
      "https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyC99sCiNRti-7-qK6imkOxinO73KkrRmP4&part=snippet&maxResults=50&playlistId=PLcDzLNDgL3KbFceKfAlS8aNp9e9tNjRCL"
    );
    const data = await response.json();

    const videos = data.items.filter((el, index) => {
      if(el.snippet.description.includes("livestream")){
        setLiveStream(el);
      } else {
        return el;
      }
    })
    setAllVideos(videos);
  }

  useEffect(() => {
    if (!allVideos.length > 0) {
      fetchVideos();
    }
  }, [allVideos]);

  return (
    <>
      {liveStream.snippet &&
        <div className="live-stream__container">
          <h2>ŽIVĚ</h2>
          <Video key={liveStream.snippet.resourceId.videoId} video={liveStream} type="live-stream"/>
        </div>
      }
        <h2 className="videos__header">
          NAŠE VIDEA
        </h2>
      <div className="videos__container">
        {allVideos.length > 0
          ? allVideos.map((video) => (
              <Video key={video.snippet.resourceId.videoId} video={video} type="video"/>
            ))
          : null}
      </div>
    </>
  );
}
