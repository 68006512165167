import React, { useEffect, useState } from "react";

export default function Video(props) {
  const [video, setVideo] = useState(null);
  const videoURL = `https://www.youtube.com/watch?v=${props.video.snippet.resourceId.videoId}`;

  useEffect(() => {
    async function fetchVideo() {
      if (!videoURL) return;
      const response = await fetch(
        `https://youtube.com/oembed?url=${videoURL}&format=json`
      );

      const data = await response.json();
      setVideo(data);
    }
    fetchVideo();
  }, [videoURL]);

  return (
    <>
      {video ? (
        <div className={props.type}>
          <iframe
            title={video.title}
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${props.video.snippet.resourceId.videoId}`}
            frameBorder="0"
            loading="lazy"
            allowFullScreen
          ></iframe>
        </div>
      ) : null}
    </>
  );
}
